import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  FormGroup,
  Checkbox,
  Button,
} from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { apiBaseUrl } from '../../services/api';
import { useSelector } from 'react-redux';

interface GoogleDirectDrawerProps {
  refreshToken: string;
  termsList: {
    id: number;
    text: string;
    matchtype: string;
    resource_name: string;
    ad_group_resource_name: string;
  }[];
  termType: string;
  campaignLock: string;
  adGroupLock: string;
  removeTerm: (termid: number) => void;
  uncheckAllTerms: () => void;
  reload?: any;
}

const GoogleDirectDrawer: React.FC<GoogleDirectDrawerProps> = ({
  refreshToken,
  termsList,
  termType,
  campaignLock,
  adGroupLock,
  removeTerm,
  uncheckAllTerms,
  //setTermsList,
  reload,
}) => {
  const [newMatchType, setNewMatchType] = useState<
    'BROAD' | 'EXACT' | 'PHRASE'
  >('BROAD');

  const state = useSelector((state: any) => state);

  /**
   * Executes keyword changes by sending a POST request to the specified API endpoint.
   *
   * @param params - The parameters required to execute keyword changes.
   * @param params.customerId - The ID of the customer.
   * @param params.accountId - The ID of the account.
   * @param params.devToken - The developer token.
   * @param params.matchType - The match type for the keywords.
   * @param params.keyword - An array of keyword objects.
   * @param params.keyword[].match_type - The match type of the keyword.
   * @param params.keyword[].text - The text of the keyword.
   * @param params.keyword[].resource_name - The resource name of the keyword.
   * @param params.keyword[].ad_group_resource_name - The resource name of the ad group.
   * @param params.keyword[].negative - Optional flag indicating if the keyword is negative.
   *
   * @returns {void}
   */
  const executeKeywordChanges = (params: {
    customerId: string;
    accountId: string;
    devToken: string;
    matchType: string;
    keyword: {
      match_type: string;
      text: string;
      resource_name: string;
      ad_group_resource_name: string;
      negative?: boolean | undefined;
    }[];
  }) => {
    axios
      .post(`${apiBaseUrl}/report/brand/campaign/google/keywords`, {
        ...params,
      })
      .then((response) => {
        console.log(`Success: report/brand/campaign/google/keywords}`);
        console.log(response);
      })
      .catch((error) => {
        console.log(`Error: report/brand/campaign/google/keywords}`);
        console.log(error);
      })
      .finally(() => {
        reload(false);
      });
  };

  const handleAddAsNegativeKeyword = async () => {
    reload(true);
    const devToken = '',
      accountId = state.brand.brand.googleAdAccountId,
      customerId = state.brand.brand.googleCustomerIds
        ? state.brand.brand.googleCustomerIds[0]
        : '',
      matchType = newMatchType,
      keyword = termsList.map((k) => {
        return {
          match_type: k.matchtype,
          text: k.text,
          resource_name: k.resource_name,
          ad_group_resource_name: k.ad_group_resource_name,
          negative: true,
        };
      });

    executeKeywordChanges({
      customerId,
      accountId,
      devToken,
      matchType,
      keyword,
    });
  };

  const handleMatchTypeChange = async () => {
    console.log(`$termsList`);
    console.log(termsList);
    reload(true);
    const devToken = '',
      accountId = state.brand.brand.googleAdAccountId,
      customerId = state.brand.brand.googleCustomerIds
        ? state.brand.brand.googleCustomerIds[0]
        : '',
      matchType = newMatchType,
      keyword = termsList.map((k) => {
        return {
          match_type: k.matchtype || '',
          text: k.text || '',
          resource_name: k.resource_name || '',
          ad_group_resource_name: k.ad_group_resource_name || '',
        };
      });

    executeKeywordChanges({
      customerId,
      accountId,
      devToken,
      matchType,
      keyword,
    });
  };

  const changeMatchType = (target: 'BROAD' | 'EXACT' | 'PHRASE') => {
    setNewMatchType(target);
  };

  return (
    <Box
      bgcolor={`#eee`}
      borderTop={`1px solid #ccc`}
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        minHeight: '100px',
        maxHeight: '30vh',
        zIndex: 1000,
        paddingLeft: `240px`,
      }}
      overflow={`auto`}
      hidden={termsList.length <= 0}
    >
      <Box padding={`10px`}>
        <Box
          display={`flex`}
          flexDirection={`row`}
          justifyContent={`space-between`}
          mb={`10px`}
        >
          <Typography color={`#096f4d`} fontWeight={600}>
            <Box component="span" sx={{ fontWeight: 800 }}>
              {termType}
            </Box>{' '}
            [{termsList.length}]
          </Typography>
          <Chip
            style={{ display: `none` }}
            label={`Uncheck all items`}
            color="primary"
            onClick={uncheckAllTerms}
          />
        </Box>

        <Box
          borderRadius={`20px`}
          bgcolor={`#fff`}
          paddingX={`20px`}
          paddingY={`5px`}
          display={`flex`}
          flexWrap={`wrap`}
          gap={`5px`}
          maxHeight={`90px`}
          overflow={`auto`}
        >
          {termsList.map((k, i) => {
            return (
              <Chip
                key={i}
                label={`${k.text}${k.matchtype ? ` [${k.matchtype}]` : ''}`}
                // onDelete={() => {
                //   removeTerm(k.id);
                // }}
              />
            );
          })}
        </Box>

        <Box marginTop={`10px`}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              <Typography color={`#096f4d`} fontWeight={600}>
                {termType === 'Keywords' ? 'Change' : 'Set'} Match Type
              </Typography>
            </FormLabel>
            <RadioGroup row>
              <FormControlLabel
                value="Broad"
                control={<Radio checked={newMatchType === 'BROAD'} />}
                label="Broad"
                onChange={() => {
                  changeMatchType('BROAD');
                }}
              />
              <FormControlLabel
                value="Exact"
                control={<Radio checked={newMatchType === 'EXACT'} />}
                label="Exact"
                onChange={() => {
                  changeMatchType('EXACT');
                }}
              />
              <FormControlLabel
                value="Phrase"
                control={<Radio checked={newMatchType === 'PHRASE'} />}
                label="Phrase"
                onChange={() => {
                  changeMatchType('PHRASE');
                }}
              />
            </RadioGroup>
          </FormControl>

          {termType === 'Keywords' ? (
            <>
              <div
                style={{ display: `flex`, gap: `10px`, alignItems: 'center' }}
              >
                <Button onClick={handleMatchTypeChange}>
                  <Chip label={`Save Changes`} color="primary" />
                </Button>

                <FormGroup row>
                  <FormControlLabel
                    control={<Checkbox disabled />}
                    label="Save to Assests"
                  />
                  <FormControlLabel
                    control={<Checkbox disabled />}
                    label="Save to Templates"
                  />
                </FormGroup>
              </div>
            </>
          ) : (
            <>
              <Box display={`flex`} gap={`1.2rem`} marginTop={`0.5rem`}>
                <Button onClick={handleMatchTypeChange}>
                  <Chip label={`Add as Keyword`} color="primary" />
                </Button>

                <Button onClick={handleAddAsNegativeKeyword}>
                  <Chip label={`Add as Negative Keyword`} color="primary" />
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default GoogleDirectDrawer;
