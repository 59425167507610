import { Box, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { CampaignAd } from '../../../types';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { adsTableColumns } from './AdListTableColumns';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { CustomLoadingOverlay } from '../../Loading';
import { useSelector } from 'react-redux';

interface AdListTableProps {
  ads: CampaignAd[];
  loading: boolean;
  onOpenPreview?: (ad: CampaignAd) => void;
}

const AdListTable: React.FC<AdListTableProps> = ({
  ads,
  loading,
  onOpenPreview,
}) => {
  const theme = useTheme();
  const isMdAndDown = useMediaQuery(theme.breakpoints.down('md'));
  const timezone = useSelector((state: any) => state?.brandTimezone?.timezone);

  const rows = ads.slice().map((ad: CampaignAd) => {
    return {
      id: ad.id,
      name: ad.name,
      description: ad.description,
      status: ad.status,
      media: ad.media,
      startTime: ad.start_time,
      endTime: ad.end_time,
      issues: ad.issues_info || [],
      adsetId: ad.adset_id,
      budget: ad?.budget,
      ad,
      isResumable: ad?.isResumable,
      onOpenPreview,
    };
  });

  return (
    <Box>
      <DataGridPremium
        className={styles.table}
        density="compact"
        rows={loading ? [] : rows}
        columns={adsTableColumns(timezone)}
        autoHeight
        hideFooterSelectedRowCount
        slots={{ loadingOverlay: CustomLoadingOverlay }}
        loading={loading}
        initialState={{
          sorting: {
            sortModel: [{ field: 'duration', sort: 'desc' }],
          },
        }}
      />
    </Box>
  );
};

export default AdListTable;
