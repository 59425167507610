import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Agency, ChangeEventType, Salesperson } from '../../types';
import { IS_DIY_ADZ } from '../../utils';
import { useDispatch } from 'react-redux';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import {
  createSalesperson,
  editSalesperson,
  getSingleSalesperson,
} from '../../services/salesperson';
import { errorMessageParser } from '../../utils/helpers/ToastHelper';
import { toggleAlert } from '../../redux/actions';
import Spinner from '../Spinner';
import { MuiTelInput } from 'mui-tel-input';
import { UserCapability } from '../../context/authContext';
import { PrimaryButton } from '../Buttons';
import { useForm } from 'react-hook-form';
import { emailAddressPattern } from '../../utils/validation';

interface AgencyUserFormProps {
  agencyUsers: Salesperson[];
  setAgencyUsers: Dispatch<SetStateAction<Salesperson[]>>;
  salespersonId: string;
  edit: boolean;
  agencyFormValues: Agency;
  setAgencyFormValues: Dispatch<SetStateAction<Agency>>;
  index: number;
  onClose: (index: number) => void;
  currentUserCapabilities?: UserCapability;
  isAdmin: boolean;
  open: boolean;
  agencyId: string;
}

const AgencyUserForm: React.FC<AgencyUserFormProps> = ({
  agencyUsers,
  setAgencyUsers,
  salespersonId,
  edit,
  agencyFormValues,
  setAgencyFormValues,
  index,
  onClose,
  currentUserCapabilities,
  isAdmin,
  open,
  agencyId,
}) => {
  const dispatch = useDispatch();
  const {
    formState: { errors },
    register,
    handleSubmit,
    clearErrors,
    setValue,
  } = useForm({ mode: 'onSubmit' });
  const [formValues, setFormValues] = useState<Salesperson>({
    name: '',
    firstname: '',
    lastname: '',
    email: '',
    cell: '',
    broker: false,
    investment: 10,
    role: 'agency',
    active: true,
    diy: IS_DIY_ADZ,
    agency: agencyId,
    capabilities: IS_DIY_ADZ
      ? {
          approveTemplate: false,
          createTemplate: false,
          createAds: false,
          viewReports: true,
          addLocations: false,
          addUser: false,
          createBrands: false,
          viewBudgets: true,
          editBudgets: false,
          agencyAdmin: true,
        }
      : null,
  });
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (salespersonId && edit) {
      getAgencyUser();
    }
  }, [edit, salespersonId]);

  const getAgencyUser = async () => {
    try {
      setFetchLoading(true);

      const response = await getSingleSalesperson(salespersonId);

      setFormValues({
        ...response,
        capabilities: response?.capabilities
          ? {
              ...response?.capabilities,
            }
          : {
              approveTemplate: false,
              createTemplate: false,
              createAds: false,
              viewReports: true,
              addLocations: false,
              addUser: false,
              editBudgets: false,
              viewBudgets: true,
              createBrands: false,
              agencyAdmin: true,
            },
      });

      setValue('firstname', response.firstname);
      setValue('lastname', response.lastname);
      setValue('email', response.email);
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const handleOnSubmit = async () => {
    try {
      setLoading(true);

      let salesperson: any;
      let message: any;

      if (edit) {
        const response = await editSalesperson(formValues, salespersonId);

        salesperson = response.updatedSalesperson;
        message = response.message;
      } else {
        const response = await createSalesperson({
          ...formValues,
        });

        salesperson = response.salesperson;
        message = response.message;
      }

      const params: Salesperson = {
        _id: salesperson._id,
        name: salesperson.name,
        salespersonId: salesperson.salespersonId,
        user: salesperson.user,
        email: salesperson.email,
        role: salesperson.role,
        capabilities: salesperson.capabilities,
      };

      let agencyUsersData: any[] = [];
      if (edit) {
        let multipleUsers: any[] = [];

        agencyUsers.forEach((data: any) => {
          if (data._id === params._id) {
            data = params;
          }

          agencyUsersData = [...agencyUsersData, data];
        });

        agencyFormValues?.users?.forEach((user: any) => {
          if (user._id === params._id) {
            user = params;
          }

          multipleUsers = [...multipleUsers, user];
        });

        setAgencyFormValues({
          ...agencyFormValues,
          users: multipleUsers,
        });
      } else {
        setAgencyFormValues({
          ...agencyFormValues,
          users: [...(agencyFormValues?.users || []), params],
        });

        agencyUsersData = [...agencyUsersData, params];
      }

      setAgencyUsers(agencyUsersData);
      onClose(index);

      dispatch(
        toggleAlert({
          toggle: true,
          message,
        }),
      );
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setLoading(false);
    }
  };

  const handleOnChange = (e: ChangeEventType) => {
    clearErrors(e.target.name);
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnChangeNumber = (newValue: string) => {
    setFormValues({ ...formValues, cell: newValue.split(' ').join('') });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1">Create Agency User</Typography>
      </Grid>

      {fetchLoading ? (
        <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
          <Spinner />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register('firstname', {
                required: true,
              })}
              autoFocus
              fullWidth
              required
              variant="standard"
              type="text"
              id="firstname"
              name="firstname"
              label="First Name"
              onChange={handleOnChange}
              value={formValues.firstname}
              InputLabelProps={{ shrink: true }}
              size="small"
              helperText={errors?.firstname && 'First name is required'}
              error={!!errors.firstname}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              {...register('lastname', {
                required: true,
              })}
              fullWidth
              required
              variant="standard"
              type="text"
              id="lastname"
              name="lastname"
              label="Last Name"
              onChange={handleOnChange}
              value={formValues.lastname}
              InputLabelProps={{ shrink: true }}
              size="small"
              helperText={errors?.lastname && 'Last name is required'}
              error={!!errors.lastname}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              {...register('email', {
                required: true,
                pattern: emailAddressPattern,
              })}
              fullWidth
              required
              variant="standard"
              type="email"
              id="email"
              name="email"
              label="Email"
              onChange={handleOnChange}
              value={formValues.email}
              InputLabelProps={{ shrink: true }}
              size="small"
              helperText={
                errors?.email?.message || (errors?.email && 'Email is required')
              }
              error={!!errors.email}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <MuiTelInput
              fullWidth
              id="cell"
              name="cell"
              label="Phone Number"
              variant="standard"
              onChange={(newValue: string) => handleOnChangeNumber(newValue)}
              value={formValues?.cell}
              InputLabelProps={{ shrink: true }}
              size="small"
              onlyCountries={['US']}
              defaultCountry="US"
            />
          </Grid>

          {IS_DIY_ADZ ? (
            <>
              <Grid item xs={12}>
                <Typography variant="body1">User Roles</Typography>
              </Grid>

              <Grid item xs={12}>
                {currentUserCapabilities?.viewReports || isAdmin ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        defaultChecked={formValues?.capabilities?.viewReports}
                      />
                    }
                    name="viewReports"
                    label="View reports"
                    value={formValues?.capabilities?.viewReports}
                    onChange={(e: any) => {
                      setFormValues({
                        ...formValues,
                        capabilities: {
                          ...formValues?.capabilities,
                          [e.target.name]: e.target.checked,
                        },
                      });
                    }}
                  />
                ) : null}

                {currentUserCapabilities?.addUser || isAdmin ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        defaultChecked={formValues?.capabilities?.addUser}
                      />
                    }
                    name="addUser"
                    label="Create user"
                    value={formValues?.capabilities?.addUser}
                    onChange={(e: any) => {
                      setFormValues({
                        ...formValues,
                        capabilities: {
                          ...formValues?.capabilities,
                          [e.target.name]: e.target.checked,
                        },
                      });
                    }}
                  />
                ) : null}

                {currentUserCapabilities?.viewBudgets || isAdmin ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        defaultChecked={formValues?.capabilities?.viewBudgets}
                      />
                    }
                    name="viewBudgets"
                    label="View budgets"
                    value={formValues?.capabilities?.viewBudgets}
                    onChange={(e: any) => {
                      setFormValues({
                        ...formValues,
                        capabilities: {
                          ...formValues?.capabilities,
                          [e.target.name]: e.target.checked,
                        },
                      });
                    }}
                  />
                ) : null}

                {currentUserCapabilities?.createBrands || isAdmin ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        defaultChecked={formValues?.capabilities?.createBrands}
                      />
                    }
                    name="createBrands"
                    label="Create brands"
                    value={formValues?.capabilities?.createBrands}
                    onChange={(e: any) => {
                      setFormValues({
                        ...formValues,
                        capabilities: {
                          ...formValues?.capabilities,
                          [e.target.name]: e.target.checked,
                        },
                      });
                    }}
                  />
                ) : null}

                {currentUserCapabilities?.editBudgets || isAdmin ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        defaultChecked={formValues?.capabilities?.editBudgets}
                      />
                    }
                    name="editBudgets"
                    label="Edit budgets"
                    value={formValues?.capabilities?.editBudgets}
                    onChange={(e: any) => {
                      setFormValues({
                        ...formValues,
                        capabilities: {
                          ...formValues?.capabilities,
                          [e.target.name]: e.target.checked,
                        },
                      });
                    }}
                  />
                ) : null}

                {currentUserCapabilities?.createTemplate || isAdmin ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        defaultChecked={
                          formValues?.capabilities?.createTemplate
                        }
                      />
                    }
                    name="createTemplate"
                    label="Create template"
                    value={formValues?.capabilities?.createTemplate}
                    onChange={(e: any) => {
                      setFormValues({
                        ...formValues,
                        capabilities: {
                          ...formValues?.capabilities,
                          [e.target.name]: e.target.checked,
                        },
                      });
                    }}
                  />
                ) : null}

                {currentUserCapabilities?.approveTemplate || isAdmin ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        defaultChecked={
                          formValues?.capabilities?.approveTemplate
                        }
                      />
                    }
                    name="approveTemplate"
                    label="Approve template"
                    value={formValues?.capabilities?.approveTemplate}
                    onChange={(e: any) => {
                      setFormValues({
                        ...formValues,
                        capabilities: {
                          ...formValues?.capabilities,
                          [e.target.name]: e.target.checked,
                        },
                      });
                    }}
                  />
                ) : null}

                {currentUserCapabilities?.createAds || isAdmin ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        defaultChecked={formValues?.capabilities?.createAds}
                      />
                    }
                    name="createAds"
                    label="Create ads"
                    value={formValues?.capabilities?.createAds}
                    onChange={(e: any) => {
                      setFormValues({
                        ...formValues,
                        capabilities: {
                          ...formValues?.capabilities,
                          [e.target.name]: e.target.checked,
                        },
                      });
                    }}
                  />
                ) : null}

                {currentUserCapabilities?.addLocations || isAdmin ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        defaultChecked={formValues?.capabilities?.addLocations}
                      />
                    }
                    name="addLocations"
                    label="Add locations"
                    value={formValues?.capabilities?.addLocations}
                    onChange={(e: any) => {
                      setFormValues({
                        ...formValues,
                        capabilities: {
                          ...formValues?.capabilities,
                          [e.target.name]: e.target.checked,
                        },
                      });
                    }}
                  />
                ) : null}

                {currentUserCapabilities?.agencyAdmin || isAdmin ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        defaultChecked={formValues?.capabilities?.agencyAdmin}
                      />
                    }
                    name="agencyAdmin"
                    label="Agency Admin"
                    value={formValues?.capabilities?.agencyAdmin}
                    onChange={(e: any) => {
                      setFormValues({
                        ...formValues,
                        capabilities: {
                          ...formValues?.capabilities,
                          [e.target.name]: e.target.checked,
                        },
                      });
                    }}
                  />
                ) : null}
              </Grid>
            </>
          ) : null}

          <Grid item xs={12}>
            <PrimaryButton
              title={edit ? 'Save Changes' : 'Add'}
              loading={loading}
              marginRight5
              handleOnClick={handleSubmit(handleOnSubmit)}
              type="button"
            />

            <PrimaryButton
              title="Cancel"
              type="button"
              handleOnClick={() => onClose(index)}
              theme="red"
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default AgencyUserForm;
