import {
  Chip,
  Box,
  Checkbox,
  FormControlLabel,
  InputProps,
  Tooltip,
} from '@mui/material';
import GoogleDirectDrawer from './GoogleDirectDrawer';
import { googleReportsColumnDefs } from './GoogleCampaignColumn';

import {
  DataGridPremium,
  GridRowGroupingModel,
  DataGridPremiumProps,
  GridColDef,
  GridRenderCellParams,
  useGridApiContext,
  useGridSelector,
  gridFilteredDescendantCountLookupSelector,
} from '@mui/x-data-grid-premium';

import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from 'react-icons/md';

import { RxDash } from 'react-icons/rx';

import Button, { ButtonProps } from '@mui/material/Button';
import { useEffect, useRef, useState, useMemo } from 'react';
import { CustomLoadingOverlay } from '../Loading';
import { toast } from 'react-toastify';
import { googleEnums, googleHelpers } from '../../utils/constants/google';
import { toCurrency } from '../../utils/numberFormatter';

interface GoogleDirectCampaignTableProps {
  refreshToken: string;
  campaigns: any[];
  ad_groups: any[];
  keywords: any[];
  search_terms: any[];
  loading: boolean;
  campaignList: string[];
  allowCheckboxes: boolean;
  reload?: any;
}

const GoogleDirectCampaignTable: React.FC<GoogleDirectCampaignTableProps> = ({
  refreshToken,
  campaigns,
  ad_groups,
  keywords,
  search_terms,
  loading,
  campaignList,
  allowCheckboxes = false,
  reload,
}) => {
  const rows: any = [];
  const [termsList, setTermsList] = useState<
    {
      id: number;
      text: string;
      matchtype: string;
      resource_name: string;
      ad_group_resource_name: string;
    }[]
  >([]);
  const [termType, setTermType] = useState<string>('keyword');

  let newTermlist: {
    matchtype: string;
    id: number;
    text: string;
    resource_name: string;
    ad_group_resource_name: string;
  }[] = [];
  let adGroupLock: null | string = null;
  let campaignLock: null | string = null;
  let termLock: null | string = null;

  const removeTermsFromDrawer = (termid: number) => {
    let newTermlist: {
      id: number;
      text: string;
      matchtype: string;
      resource_name: string;
      ad_group_resource_name: string;
    }[] = [];

    rows.forEach((r: any) => {
      if (r.id === termid) {
        r.checked = false;
      }
      if (r.checked) {
        newTermlist.push({
          id: r.id,
          text: r.text,
          matchtype: r.matchtype,
          resource_name: r.resource_name,
          ad_group_resource_name: r.ad_group_resource_name,
        });
      }
    });

    if (newTermlist.length <= 0) {
      adGroupLock = null;
      termLock = null;
    }
    setTermsList(newTermlist);
  };

  ad_groups
    .filter((c: any) => {
      return campaignList.includes(c.campaign.id.toString());
    })
    .sort(
      //sort by campaign.status
      (a, b) => a.campaign.status - b.campaign.status,
    )
    .forEach((adgroup: any, ci: number) => {
      if (
        adgroup.campaign.status === 4 ||
        (adgroup.campaign.status === 3 &&
          adgroup.campaign.metrics.cost_micros === 0)
      ) {
        //if campaign is removed or paused with no cost, skip
        return;
      }
      rows.push({
        id: `c${ci}`,
        path: [`campagin-[${ci}]`],
        text: '',
        campaign: adgroup.campaign.name,
        status: googleEnums.campaignStatus[adgroup.campaign.status],
        actype: adgroup.campaign.advertising_channel_type,
        Clicks: adgroup.campaign.metrics ? adgroup.campaign.metrics.clicks : 0,
        Leads: adgroup.campaign.metrics
          ? adgroup.campaign.metrics.conversions
          : 0,
        Costs: adgroup.campaign.metrics
          ? googleHelpers.micros(adgroup.campaign.metrics.cost_micros)
          : 0,
        Impressions: adgroup.campaign.metrics
          ? adgroup.campaign.metrics.impressions
          : 0,
        CPC: adgroup.campaign.metrics
          ? googleHelpers.micros(adgroup.campaign.metrics.average_cpc)
          : 0,
        CPM: adgroup.campaign.metrics
          ? googleHelpers.micros(adgroup.campaign.metrics.average_cpm)
          : 0,
        CPL:
          adgroup.campaign.metrics.conversions > 0
            ? googleHelpers.micros(
                adgroup.campaign.metrics.cost_micros /
                  adgroup.campaign.metrics.conversions,
              )
            : 0,
        checked: false,
      });
      adgroup.ad_groups
        .filter(
          (ag: any) =>
            ag.status !== 4 && (ag.status !== 3 || ag.metrics.cost_micros > 0),
        )
        .forEach((adg: any, ai: number) => {
          rows.push({
            id: `c${ci}-ag${ai}`,
            path: [`campagin-[${ci}]`, `ad_group-[${ai}]`],
            campaign: adgroup.campaign.name,
            status: googleEnums.campaignStatus[adgroup.campaign.status],
            actype: adgroup.campaign.advertising_channel_type,
            ad_group: adg.name,
            ad_group_resource_name: adg.resource_name,
            adGroupId: adg.id,
            text: '',
            Clicks: adg.metrics ? adg.metrics.clicks : 0,
            Leads: adg.metrics ? adg.metrics.conversions : 0,
            Costs: adg.metrics
              ? googleHelpers.micros(adg.metrics.cost_micros)
              : 0,
            Impressions: adg.metrics ? adg.metrics.impressions : 0,
            CPC: adg.metrics
              ? googleHelpers.micros(adg.metrics.average_cpc)
              : 0,
            CPM: adg.metrics
              ? googleHelpers.micros(adg.metrics.average_cpm)
              : 0,
            CPL:
              adg.metrics.conversions > 0
                ? googleHelpers.micros(
                    adg.metrics.cost_micros / adg.metrics.conversions,
                  )
                : 0,
            checked: false,
          });

          if (adgroup.campaign.advertising_channel_type !== `DISPLAY`) {
            const kws = keywords.filter((k) => k.ad_group.id === adg.id);
            const sts = search_terms.filter((st) => st.ad_group.id === adg.id);
            rows.push({
              id: `c${ci}-ag${ai}-kwx`,
              path: [`campagin-[${ci}]`, `ad_group-[${ai}]`, `Keywords`],
              campaign: adgroup.campaign.name,
              status: googleEnums.campaignStatus[adgroup.campaign.status],
              actype: adgroup.campaign.advertising_channel_type,
              ad_group: adg.name,
              ad_group_resource_name: adg.resource_name,
              adGroupId: adg.id,
              text: '',
              term: kws.length > 0 ? `Keywords [${kws.length}]` : `No Keywords`,
              Clicks: '',
              Leads: '',
              Costs: '',
            });
            kws.forEach((kw: any, ki: number) => {
              rows.push({
                id: `c${ci}-ag${ai}-kw${ki}`,
                path: [
                  `campagin-[${ci}]`,
                  `ad_group-[${ai}]`,
                  `Keywords`,
                  `keyword_view-[${ki}]`,
                ],
                campaign: adgroup.campaign.name,
                status: googleEnums.campaignStatus[adgroup.campaign.status],
                actype: adgroup.campaign.advertising_channel_type,
                ad_group: adg.name,
                ad_group_resource_name: adg.resource_name,
                adGroupId: adg.id,
                text: kw.ad_group_criterion.keyword.text,
                matchtype:
                  googleEnums.keywords[
                    kw.ad_group_criterion.keyword.match_type
                  ],
                resource_name: kw.ad_group_criterion.resource_name,
                term: `Keywords`,
                Clicks: kw.metrics ? kw.metrics.clicks : 0,
                Leads: kw.metrics ? kw.metrics.conversions : 0,
                Costs: kw.metrics.cost_micros
                  ? googleHelpers.micros(kw.metrics.cost_micros)
                  : 0,
                Impressions: kw.metrics ? kw.metrics.impressions : 0,
                CPC: kw.metrics.average_cpc
                  ? googleHelpers.micros(kw.metrics.average_cpc)
                  : 0,
                CPM: kw.metrics.average_cpm
                  ? googleHelpers.micros(kw.metrics.average_cpm)
                  : 0,
                CPL:
                  kw.metrics.conversions > 0
                    ? googleHelpers.micros(
                        kw.metrics.cost_micros / kw.metrics.conversions,
                      )
                    : 0,
                checked: false,
              });
            });
            rows.push({
              id: `c${ci}-ag${ai}-stx}`,
              path: [`campagin-[${ci}]`, `ad_group-[${ai}]`, `Search Term`],
              campaign: adgroup.campaign.name,
              status: googleEnums.campaignStatus[adgroup.campaign.status],
              actype: adgroup.campaign.advertising_channel_type,
              ad_group: adg.name,
              ad_group_resource_name: adg.resource_name,
              adGroupId: adg.id,
              text: '',
              term:
                sts.length > 0
                  ? `Search Terms [${sts.length}]`
                  : `No Search Terms`,
              Clicks: '',
              Leads: '',
              Costs: '',
            });
            sts.forEach((st: any, si: number) => {
              rows.push({
                id: `c${ci}-ag${ai}-st${si}`,
                path: [
                  `campagin-[${ci}]`,
                  `ad_group-[${ai}]`,
                  `Search Term`,
                  `search_term_view-[${si}]`,
                ],
                campaign: adgroup.campaign.name,
                status: googleEnums.campaignStatus[adgroup.campaign.status],
                actype: adgroup.campaign.advertising_channel_type,
                ad_group: adg.name,
                ad_group_resource_name: adg.resource_name,
                adGroupId: adg.id,
                text: st.search_term_view.search_term,
                term: 'Search Term',
                Clicks: st.metrics ? st.metrics.clicks : 0,
                Leads: st.metrics ? st.metrics.conversions : 0,
                Impressions: st.metrics ? st.metrics.impressions : 0,
                Costs: st.metrics.cost_micros
                  ? googleHelpers.micros(st.metrics.cost_micros)
                  : 0,
                CPC: st.metrics.average_cpc
                  ? googleHelpers.micros(st.metrics.average_cpc)
                  : 0,
                CPM: st.metrics.average_cpm
                  ? googleHelpers.micros(st.metrics.average_cpm)
                  : 0,
                CPL:
                  st.metrics.conversions > 0
                    ? googleHelpers.micros(
                        st.metrics.cost_micros / st.metrics.conversions,
                      )
                    : 0,
                checked: false,
              });
            });
          }
        });
    });

  const CustomGridTreeDataGroupingCell = (props: GridRenderCellParams) => {
    const { id, row, field, rowNode } = props;
    const apiRef = useGridApiContext();
    const filteredDescendantCountLookup = useGridSelector(
      apiRef,
      gridFilteredDescendantCountLookupSelector,
    );
    const filteredDescendantCount =
      filteredDescendantCountLookup[rowNode.id] ?? 0;
    const iconsProps = {
      size: `2em`,
    };
    const handleClick: ButtonProps['onClick'] = (event) => {
      if (rowNode.type !== 'group') {
        return;
      }
      apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);

      apiRef.current.setCellFocus(id, field);
    };

    const handleCheckBoxClick: InputProps['onChange'] = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      if (rowNode.type === `leaf`) {
        //addToTerms(row);

        const { id, adGroupId, text, checked, term } = row;
        if (adGroupLock && adGroupLock !== adGroupId) {
          toast.error(`You can only select terms from the same ad group`, {
            autoClose: 5000,
            position: 'bottom-right',
          });
          return;
        }

        if (termLock && termLock !== term) {
          toast.error(`You can only select terms from the same ad group`, {
            autoClose: 5000,
            position: 'bottom-right',
          });
          return;
        }

        newTermlist = [];
        rows.forEach((r: any) => {
          if (r.id === id) {
            r.checked = !checked;
            adGroupLock = adGroupId;
            termLock = term;
            campaignLock = r.campaign;
            setTermType(term);
          }

          if (r.checked) {
            newTermlist = [
              ...newTermlist,
              {
                matchtype: r.matchtype,
                id: r.id,
                text: r.text,
                resource_name: r.resource_name,
                ad_group_resource_name: r.ad_group_resource_name,
              },
            ];
          }
        });

        if (newTermlist.length > 0) {
        } else {
          adGroupLock = null;
          termLock = null;
          campaignLock = null;
        }

        setTermsList(newTermlist);
      }
    };

    return (
      <Box sx={{ ml: rowNode.depth !== 3 ? rowNode.depth * 2 : 4 }}>
        {rowNode.type === `group` || rowNode.depth < 3 ? (
          <Tooltip
            title={`${row.ad_group || row.campaign}${
              row.actype ? ` - ` + row.actype : ``
            }`}
            disableInteractive
          >
            <Button
              onClick={handleClick}
              tabIndex={-1}
              size="small"
              style={{
                display: `flex`,
                gap: `1em`,
                height: `2.7em`,
              }}
            >
              {rowNode.depth === 0 ? (
                <>
                  <Chip
                    sx={{
                      display: `none`,
                    }}
                    color={'secondary'}
                    size="small"
                    style={{ width: '85px' }}
                    label={row.status}
                  />
                  {row.actype !== `PERFORMANCE MAX` ? (
                    //@ts-ignore
                    rowNode.childrenExpanded ? (
                      <MdOutlineArrowDropUp {...iconsProps} />
                    ) : (
                      <MdOutlineArrowDropDown {...iconsProps} />
                    )
                  ) : (
                    <RxDash {...iconsProps} />
                  )}
                  {row.campaign}{' '}
                  <Chip color={'secondary'} size="small" label={row.actype} />
                </>
              ) : rowNode.depth === 1 ? (
                <>
                  {row.actype !== `DISPLAY` ? (
                    //@ts-ignore
                    rowNode.childrenExpanded ? (
                      <MdOutlineArrowDropUp {...iconsProps} />
                    ) : (
                      <MdOutlineArrowDropDown {...iconsProps} />
                    )
                  ) : (
                    <RxDash {...iconsProps} />
                  )}

                  {row.ad_group}
                </>
              ) : (
                <>
                  {
                    //@ts-ignore
                    rowNode.children ? (
                      //@ts-ignore
                      rowNode.childrenExpanded ? (
                        <MdOutlineArrowDropUp {...iconsProps} />
                      ) : (
                        <MdOutlineArrowDropDown {...iconsProps} />
                      )
                    ) : (
                      <RxDash {...iconsProps} />
                    )
                  }
                  {row.term}
                </>
              )}
            </Button>
          </Tooltip>
        ) : !allowCheckboxes ? (
          <Tooltip title={row.text} disableInteractive>
            <Button>{row.text}</Button>
          </Tooltip>
        ) : (
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleCheckBoxClick}
                defaultChecked={row.checked}
                hidden={true}
                disabled={
                  (adGroupLock && adGroupLock !== row.adGroupId) ||
                  (termLock && termLock !== row.term)
                }
              />
            }
            label={row.text}
          />
        )}
      </Box>
    );
  };

  const groupingColDef: DataGridPremiumProps['groupingColDef'] = {
    headerName: 'Campaigns',
    sortable: true,
    minWidth: 300,
    flex: 1,
    headerClassName: 'bg-green',
    renderCell: (params: any) => <CustomGridTreeDataGroupingCell {...params} />,
  };

  const mainArea = useMemo(
    () => (
      <DataGridPremium
        treeData
        getRowId={(row) => row.id}
        getTreeDataPath={(row) => row.path}
        rows={rows}
        columns={googleReportsColumnDefs}
        density="compact"
        hideFooterSelectedRowCount
        slots={{ loadingOverlay: CustomLoadingOverlay }}
        groupingColDef={groupingColDef}
        hideFooter
        initialState={{
          sorting: { sortModel: [{ field: 'Costs', sort: 'desc' }] },
        }}
      />
    ),
    [],
  );

  const uncheckAllTerms = () => {
    adGroupLock = null;
    termLock = null;
    rows.forEach((r: any) => {
      r.checked = false;
    });

    setTermsList([]);
  };

  return (
    <>
      <Box
        sx={{
          height: '100vh',
          minHeight: '200px',
          maxHeight: '500px',
          width: '90%',
          marginX: 'auto',
          '& .bg-green': {
            backgroundColor: '#096f4d',
            color: '#ffffff',
          },
          paddingBottom: '200px',
        }}
      >
        <div style={{ height: 550, margin: `0 -65px` }}>{mainArea}</div>
        <div style={{ height: `50vh` }} hidden={termsList.length === 0} />
      </Box>

      <GoogleDirectDrawer
        refreshToken={refreshToken}
        termType={termType}
        termsList={termsList}
        campaignLock={campaignLock}
        adGroupLock={adGroupLock}
        removeTerm={removeTermsFromDrawer}
        uncheckAllTerms={uncheckAllTerms}
        reload={reload}
      />
    </>
  );
};

export default GoogleDirectCampaignTable;
