/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Stack,
  Chip,
  Switch,
  Autocomplete,
  InputAdornment,
  Checkbox,
} from '@mui/material';
import {
  Agency,
  Brand,
  BrandErrors,
  CannedResponse,
  ChangeEventType,
  Fee,
  InvestmentRequirement,
  InvitationErrors,
  InviteInfo,
  PageFormFields,
  Salesperson,
} from '../../types';
import { PrimaryButton } from '../Buttons';
import { LeadSource } from '../../types';
import CircularLoading from '../CircularLoading';
import DeleteIcon from '@mui/icons-material/Delete';
import { red } from '@mui/material/colors';
import {
  ADMIN,
  AGENCY,
  CANNED_RESPONSE_FIELDS,
  IS_DIY_ADZ,
  MARKETING_MANAGER,
  SUPER_ADMIN,
} from '../../utils';
import styles from '../../assets/styles/components/Forms/Form.module.scss';
import ConnectFacebookPageForm from './ConnectFacebookPageForm';
import { createBrand, editBrand, getSingleBrand } from '../../services/brand';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context';
import { fetchLeadSource } from '../../services/clientTether';
import { connectFbPage, disconnectPage } from '../../services/page';
import { getAgencies, getAgencyUsers } from '../../services/agency';
import {
  getAllBrandManagers,
  getAllMarketingManagers,
  getAllSalesperson,
} from '../../services/salesperson';
import { useDispatch, useSelector } from 'react-redux';
import { XsOnly } from '../../utils/breakpoints';
import PageHeader from '../PageHeader';
import AddIcon from '@mui/icons-material/Add';
import BrandUserForm from './BrandUserForm';
import { setBrand, setBrands, toggleAlert } from '../../redux/actions';
import { inviteSalesperson } from '../../services/invitation';
import InviteModal from '../Modal/InviteModal';
import { humanizeString } from '../../utils/stringModifier';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import { FEE_TYPES } from '../../utils/helpers/stripeHelper';
import { createOrUpdateFee, fetchAllFeesByBrand } from '../../services/fee';
import ConfirmNavigationModal, { usePrompt } from '../Navigation/RoutePrompt';
import { buildAccessToken } from '../../utils/helpers/DefaultTokenBuilder';
import DiySettingsForm from './Diy/DiySettingsForm';
import { retriveStripeSubscription } from '../../services/stripe/subscription';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import GroupRemoveOutlinedIcon from '@mui/icons-material/GroupRemoveOutlined';
import { DiySettings, DiySettingsError } from '../../types/IDiy';
import {
  createOrUpdateDiySettings,
  getSingleDiySetting,
} from '../../services/diy';
import {
  AnalyticTools,
  BusinessType,
  analyticTools,
} from '../../utils/helpers/BrandHelpers';

const MAX_USER_COUNT: number = 10;

const BrandForm: React.FC = () => {
  const brand: Brand = useSelector((state: any) => state.brand?.brand);

  const brands: Brand[] = useSelector((state: any) => state.brands?.brands);
  const dispatch = useDispatch();
  const pathLocation = useLocation();
  const { state } = useContext(AuthContext);
  const accessToken = state.authUser?.accessToken;
  const role = state.role;
  const isAdmin = role === ADMIN;
  const isAgency = role === AGENCY;
  const isSuperAdmin = role === SUPER_ADMIN;
  const isMarketingManager = role === MARKETING_MANAGER;
  const roleBasedId = state.roleBasedId;
  const capabilities = state.capabilities;
  const canConnectPage = isAgency || isSuperAdmin || isAdmin;
  const pathnames = pathLocation.pathname.split('/').filter((x) => x);
  const isEdit =
    pathnames.includes('preferencez') ||
    pathnames.includes('edit') ||
    pathnames.includes('preferences');
  const navigate = useNavigate();
  const canCreateBrand =
    isSuperAdmin ||
    isAdmin ||
    (!(isSuperAdmin || isAdmin) && capabilities?.createBrands);

  const [selectedForm, setSelectedForm] = useState<number>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [defaultCannedResponse] = useState<CannedResponse[]>([
    {
      message: `Hi <<lead>>! Good day, I'm <<salesperson>> of <<brand>>, how can I help you?`,
      isDeleted: false,
    },
    {
      message: `Hi <<lead>> you want to schedule a call? Here's my Calendly link <<calendly_link>>.`,
      isDeleted: false,
    },
    {
      message:
        'I am glad I was able to help. Please tell me if you have any more queries 🙂',
      isDeleted: false,
    },
  ]);
  const [formValues, setFormValues] = useState<Brand>({
    brand: '',
    allLeads: 'No',
    zipcodereq: 'No',
    financialreq: 'Yes',
    redirect: 'No',
    redirectlink: '',
    redirecttext: '',
    industryRequirement: 'No',
    schedulecall: 'No',
    industryQuestion: '',
    calendly: '',
    salesperson: {
      name: '',
      _id: '',
      email: '',
      role: '',
      user: {
        email: '',
      },
    },
    salespersonTitle: '',
    page: null,
    phoneNumReq: 'No',
    budgetOffset: 100,
    budgetPercentageFee: 18,
    clientTetherConfig: {
      accessToken: '',
      webKey: '',
      leadSourceId: '',
    },
    cannedResponses: [
      {
        message: `Hi <<lead>>! Good day, I'm <<salesperson>> of <<brand>>, how can I help you?`,
        isDeleted: false,
      },
      {
        message: `Hi <<lead>> you want to schedule a call? Here's my Calendly link <<calendly_link>>.`,
        isDeleted: false,
      },
      {
        message:
          'I am glad I was able to help. Please tell me if you have any more queries 🙂',
        isDeleted: false,
      },
    ],
    manager: {
      name: '',
      _id: '',
      email: '',
      role: '',
      user: {
        email: '',
      },
    },
    marketingManager: {
      name: '',
      _id: '',
      email: '',
      role: '',
      user: {
        email: '',
      },
    },
    active: true,
    paused: false,
    lastPausedAt: null,
    facebookBusinessIds: [],
    googleCustomerIds: [],
    allowLocations: false,
    allowLocationsSetupPayment: false,
    agency: {
      _id: '',
      agency: '',
    },
    __type: 'brand',
    allowSetupDepositAccount: false,
    allowSetupPayment: false,
    industry: '',
    businessType: '',
    diy: IS_DIY_ADZ,
    users: [],
    analyticTools: [],
  });
  const [brandFeesForm, setBrandFeesForm] = useState<Fee[]>([]);
  const [locationFeesForm, setLocationFeesForm] = useState<Fee[]>([]);
  const [investmentRequirement, setInvestmentRequirement] =
    useState<InvestmentRequirement>({
      text: '',
      num: 0,
      top: 0,
    });
  const [errors, setErrors] = useState<BrandErrors>({
    brand: '',
    agency: '',
    salesperson: '',
    users: '',
    locationLabel: '',
    businessType: '',
    industry: '',
    lat: '',
    lng: '',
  });
  const [diySettingsErrors, setDiySettingsErrors] = useState<DiySettingsError>({
    defaultUrl: '',
    defaultUser: '',
    //defaultEmploymentUrl: '',
  });
  const [leadSources, setLeadSources] = useState<LeadSource[]>([]);
  const [openPageList, setOpenPageList] = useState<boolean>(false);
  const [agencies, setAgencies] = useState<Agency[]>([]);
  const [salespersons, setSalespersons] = useState<Salesperson[]>([]);
  const [managers, setManagers] = useState<Salesperson[]>([]);
  const [marketingManagers, setMarketingManagers] = useState<Salesperson[]>([]);
  const [showSalespersonForm, setShowSalespersonForm] =
    useState<boolean>(false);
  const [showManagerForm, setShowManagerForm] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [inviteLoading, setInviteLoading] = useState<boolean>(false);
  const [invitationErrors, setInvitationErrors] = useState<InvitationErrors>({
    email: '',
  });
  const [openInviteModal, setOpenInviteModal] = useState<boolean>(false);
  const [invitationInfo, setInvitationInfo] = useState<InviteInfo>({
    name: '',
    email: '',
    id: '',
    role: '',
  });
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [openConfirmNavModal, setOpenConfirmNavModal] =
    useState<boolean>(false);
  const [autoUnblockingTx, setAutoUnblockingTx] = useState<any>(null);
  const [defaultAccessToken, setDefaultAccessToken] = useState<string>(null);
  const [subscription, setSubscription] = useState<any>(null);
  const [showMultipleUserForms, setShowMultipleUserForms] = useState<boolean[]>(
    [false],
  );
  const [formCount, setFormCount] = useState<number>(1);
  const [diySettingForm, setDiySettingForm] = useState<DiySettings>({
    ref: brand?._id,
    docModel: 'Brand',
    allowTrafficAds: true,
    allowLeadAds: false,
    allowMessengerAds: false,
    allowAppAds: false,
    allowAllGeolocation: false,
    locationTypes: ['address'],
    excludedLocationTypes: [],
    excludedGeolocations: null,
    excludeGeolocation: false,
    defaultUrl: '',
    defaultEmploymentUrl: '',
    defaultCalendarUrl: '',
    defaultPrivacyPolicyUrl: '',
    appDeepLink: '',
    maxCityRadius: 50,
    maxAddressRadius: 50,
    allowSetupOwnAccount: formValues?.__type === 'brand',
    geolocation: null,
    facebookPage: null,
    instagramAccount: null,
    fbAdAccount: null,
    defaultLocation: null,
    defaultUser: null,
    targetCountries: [{ code: 'US', label: 'United States', phone: '1' }],
  });
  const [diySettingLoading, setDiySettingLoading] = useState<boolean>(false);

  useEffect(() => {
    handleBuildProviderTokens();
  }, []);

  useEffect(() => {
    (async () => {
      if (
        formValues.clientTetherConfig?.accessToken &&
        formValues.clientTetherConfig?.webKey
      ) {
        getLeadSource(
          formValues.clientTetherConfig?.accessToken,
          formValues.clientTetherConfig?.webKey,
        );
      }
    })();
  }, [
    formValues.clientTetherConfig?.accessToken,
    formValues.clientTetherConfig?.webKey,
  ]);

  usePrompt(setOpenConfirmNavModal, setAutoUnblockingTx, isDirty);

  useEffect(() => {
    resetForm();
    resetDiyForm();

    allAgenciesAndSalesperson();

    if (isEdit && brand?._id) {
      getDiySettings(brand);
      getBrand(brand?._id);
      getBrandFees(brand, 'brand');
      getBrandFees(brand, 'location');
    }

    if (brand) getSubscription(brand);
  }, [brand, isEdit]);

  useEffect(() => {
    if (!isEdit) {
      if (formValues.allowSetupPayment) {
        buildBrandFeesForm('brand', []);
      }

      if (formValues.allowLocations) {
        buildBrandFeesForm('location', []);
      }
    }
  }, [formValues?.allowSetupPayment, formValues?.allowLocations, isEdit]);

  useEffect(() => {
    if (formValues?.businessType === BusinessType.FRANDEV) {
      setDiySettingForm({
        ...diySettingForm,
        allowLeadAds: true,
      });
    }
  }, [formValues?.industry]);

  const getBrand = async (brandId: string) => {
    try {
      setFetchLoading(true);

      const response: Brand = await getSingleBrand(brandId);

      setFormValues({
        ...response,
        cannedResponses: response?.cannedResponses || defaultCannedResponse,
      });
      setInvestmentRequirement(response.investmentRequirement);
      setFormCount(response?.users?.length || 1);
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const getLeadSource = async (accessToken: string, webKey: string) => {
    try {
      setFetchLoading(true);

      const response = await fetchLeadSource(accessToken, webKey);
      const { data, Message, ResultCode } = response.data;
      if (data && ResultCode === 'CT_200') {
        setLeadSources(data);
      } else {
        console.log(`Client Tether Error (${ResultCode}: ${Message})`);
        setLeadSources([]);
      }
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  const allAgenciesAndSalesperson = async () => {
    try {
      setFetchLoading(true);

      const agencyResponse = await getAgencies(
        1,
        1000,
        undefined,
        undefined,
        undefined,
        IS_DIY_ADZ,
      );
      const agencies = agencyResponse.data.map((agency: Agency) => {
        return {
          _id: agency._id,
          agency: agency.agency,
          agencyId: agency.agencyId,
        };
      });

      const agencyUsersReponse = await getAgencyUsers();

      const salespersonsResponse = await getAllSalesperson(
        1,
        1000,
        'active',
        undefined,
        undefined,
        undefined,
        IS_DIY_ADZ ? true : undefined,
        IS_DIY_ADZ,
      );
      const managersResponse = await getAllBrandManagers(
        1,
        1000,
        undefined,
        IS_DIY_ADZ,
      );
      const marketingManagersResponse = await getAllMarketingManagers(
        1,
        1000,
        undefined,
        IS_DIY_ADZ,
      );
      const agencyUsers = agencyUsersReponse.data.map(
        (agencyUser: Salesperson) => {
          return {
            _id: agencyUser._id,
            name: agencyUser.name,
            salespersonId: agencyUser.salespersonId,
            email: agencyUser.email,
            role: agencyUser.role,
            user: agencyUser.user,
          };
        },
      );
      const salespersons = salespersonsResponse.data.map(
        (salesperson: Salesperson) => {
          return {
            _id: salesperson._id,
            name: salesperson.name,
            salespersonId: salesperson.salespersonId,
            email: salesperson.email,
            role: salesperson.role,
            user: salesperson.user,
          };
        },
      );
      const managers = managersResponse.data.map((salesperson: Salesperson) => {
        return {
          _id: salesperson._id,
          name: salesperson.name,
          salespersonId: salesperson.salespersonId,
          email: salesperson.email,
          role: salesperson.role,
          user: salesperson.user,
        };
      });
      const marketingManagers = marketingManagersResponse.data.map(
        (marketingManager: Salesperson) => {
          return {
            _id: marketingManager._id,
            name: marketingManager.name,
            salespersonId: marketingManager.salespersonId,
            email: marketingManager.email,
            role: marketingManager.role,
            user: marketingManager.user,
          };
        },
      );

      setSalespersons(
        IS_DIY_ADZ && !isSuperAdmin && !isAdmin ? agencyUsers : salespersons,
      );
      setManagers(managers);
      setMarketingManagers(marketingManagers);

      setAgencies(agencies);
    } catch (error: any) {
      console.log(error.message);
      dispatch(
        toggleAlert({
          toggle: true,
          message: error.response.data.message,
          type: 'error',
        }),
      );
    } finally {
      setFetchLoading(false);
    }
  };

  const getBrandFees = async (brand: Brand, entity?: string) => {
    try {
      setLoading(true);

      const response = await fetchAllFeesByBrand(brand?._id, entity);

      buildBrandFeesForm(entity, response.data, brand);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const getSubscription = async (brand: Brand) => {
    try {
      const response = await retriveStripeSubscription(brand?._id);

      setSubscription(response.data);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const getDiySettings = async (brand: Brand) => {
    try {
      setDiySettingLoading(true);

      const response = await getSingleDiySetting(brand?._id);

      setDiySettingForm(response.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setDiySettingLoading(false);
    }
  };

  const handleBuildProviderTokens = () => {
    buildAccessToken(accessToken, setDefaultAccessToken);
  };

  const buildBrandFeesForm = (
    entity: string,
    response: Fee[],
    brand?: Brand,
  ) => {
    let payloads: Fee[] = [];

    FEE_TYPES.forEach((feeType: string, index: number) => {
      const fee = response.find((data: Fee) => {
        return data.type === feeType;
      });

      if (fee) {
        payloads = [...payloads, fee];
      } else {
        const payload: Fee = {
          name: feeType === 'ad-budget' ? 'Ad Budget' : humanizeString(feeType),
          ref: brand?._id,
          docModel: 'Brand',
          oneTime: feeType === 'setup',
          type: feeType,
          amount: (fee?.amount || 0) === 0 ? 0 : fee?.amount || 0,
          provider: 'stripe',
          enabled: true,
          entity,
          position: index + 1,
        };

        payloads = [...payloads, payload];
      }
    });

    if (entity === 'brand') {
      setBrandFeesForm(payloads);
    } else if (entity === 'location') {
      setLocationFeesForm(payloads);
    }
  };

  const handleOnSubmit = async () => {
    try {
      setLoading(true);

      if (IS_DIY_ADZ) {
        if (!diySettingForm?.defaultUrl) {
          setLoading(false);
          return setDiySettingsErrors({
            defaultUrl: 'Default website URL is required',
          });
        }

        /**
         * TODO: Re-enable if we required default employment URL
        if (!diySettingForm?.defaultEmploymentUrl) {
          setLoading(false);
          return setDiySettingsErrors({
            defaultUrl: 'Default employment URL is required',
          });
        }
        */

        if (
          diySettingForm?.allowSetupOwnAccount &&
          !diySettingForm?.defaultUser
        ) {
          setLoading(false);
          return setDiySettingsErrors({
            defaultUser: 'Default user is required',
          });
        }
      }

      setIsDirty(false);

      let brandFeePayload: Fee[] = [];
      let locationFeePayload: Fee[] = [];
      let obj: Brand = {
        investmentRequirement,
      };

      Object.keys(formValues).forEach((key) => {
        if (formValues[key as keyof Brand] !== '') {
          obj = { ...obj, [key]: formValues[key as keyof Brand] };
        }
      });

      if (isEdit) {
        const response = await editBrand(
          {
            ...obj,
            cannedResponses: buildCannedResponses(),
            diy: IS_DIY_ADZ,
          },
          brand?._id,
        );

        setLoading(false);

        let temp: Brand[] = [];
        brands.forEach((brand: Brand) => {
          if (brand?._id === response.updatedBrand._id) {
            brand = response.updatedBrand;
          }

          temp = [...temp, brand];
        });
        dispatch(setBrand(response.updatedBrand));
        dispatch(setBrands(temp));

        brandFeesForm.forEach((fee: Fee) => {
          fee.ref = response.updatedBrand._id;
          brandFeePayload = [...brandFeePayload, fee];
        });

        locationFeesForm.forEach((fee: Fee) => {
          fee.ref = response.updatedBrand._id;
          locationFeePayload = [...locationFeePayload, fee];
        });

        if (IS_DIY_ADZ) setupDiySetting(response.updatedBrand._id);

        dispatch(
          toggleAlert({
            toggle: true,
            message: 'Brand updated successfully',
          }),
        );
      } else {
        const { brand } = await createBrand({
          ...obj,
          cannedResponses: buildCannedResponses(),
          diy: IS_DIY_ADZ,
        });

        let temp: Brand[] = [...brands, brand];
        dispatch(setBrand(brand));
        dispatch(setBrands(temp));

        brandFeesForm.forEach((fee: Fee) => {
          fee.ref = brand._id;
          brandFeePayload = [...brandFeePayload, fee];
        });

        locationFeesForm.forEach((fee: Fee) => {
          fee.ref = brand._id;
          locationFeePayload = [...locationFeePayload, fee];
        });

        if (IS_DIY_ADZ) setupDiySetting(brand._id);

        dispatch(
          toggleAlert({
            toggle: true,
            message: 'Brand created successfully',
          }),
        );

        const redirectUrl = IS_DIY_ADZ
          ? brand?.analyticTools.includes(AnalyticTools.SCORECARDZ)
            ? '/scorecardz'
            : '/trendz'
          : '/dashboard';

        navigate(redirectUrl);

        handleCreateOrUpdateFees('brand', brandFeePayload);
      }
      setDiySettingsErrors({
        defaultUrl: '',
        defaultUser: '',
      });

      handleCreateOrUpdateFees('location', locationFeePayload);
    } catch (error: any) {
      setIsDirty(true);
      const err = error.response.data;
      console.log(err);
      console.log(error.message);
      setErrors({
        brand: err?.brand,
        agency: err?.agency,
        salesperson: err?.salesperson,
        users: err?.users,
        locationLabel: err?.locationLabel,
        businessType: err?.businessType,
        industry: err?.industry,
        lat: err?.lat,
        lng: err?.lng,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCreateOrUpdateFees = async (entity: string, payload: Fee[]) => {
    try {
      const IS_BRAND = entity === 'brand';
      const IS_LOCATION = entity === 'location';

      const response = await createOrUpdateFee(payload);

      if (IS_BRAND) {
        setBrandFeesForm(response?.data || []);
      } else if (IS_LOCATION) {
        setLocationFeesForm(response?.data || []);
      }
    } catch (error: any) {
      dispatch(
        toggleAlert({
          toggle: true,
          message: error.message,
          type: 'error',
        }),
      );
    }
  };

  const setupDiySetting = async (brandId: string) => {
    try {
      const response = await createOrUpdateDiySettings(brandId, diySettingForm);

      setDiySettingForm(response.data);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const handleInviteSalesperson = async (email: string) => {
    try {
      setInviteLoading(true);
      const response = await inviteSalesperson({
        email,
        clientUri: window.location.host,
        diy: IS_DIY_ADZ,
      });
      setOpenInviteModal(false);

      let users: any[] = [];
      formValues?.users.forEach((user: any) => {
        if (user.email === email) {
          user.invitedAt = response?.data?.invitedAt;
        }

        users = [...users, user];
      });

      setFormValues({ ...formValues, users });

      dispatch(
        toggleAlert({
          toggle: true,
          message: response?.message,
        }),
      );
    } catch (error: any) {
      const err = error.response.data;

      setInvitationErrors({
        ...invitationErrors,
        email: err.email,
      });
    } finally {
      setInviteLoading(false);
    }
  };

  const handleConnectPage = async (
    page: any,
    objectId: string,
    model: string,
  ) => {
    try {
      setIsDirty(true);
      setLoading(true);
      const formObj: PageFormFields = {
        accessToken: page?.accessToken,
        name: page?.name,
        fbPageId: page?.fbPageId,
        imageUrl: page?.imageUrl,
        objectId,
        model,
      };
      const data = await connectFbPage(formObj);
      if (data) {
        const response = {
          _id: data.page?._id,
          accessToken: data.page?.accessToken,
          name: data.page?.name,
          fbPageId: data.page?.fbPageId,
          imageUrl: data.page?.imageUrl,
        };
        setFormValues({
          ...formValues,
          page: response,
        });

        handleClosePageListModal();
        dispatch(
          toggleAlert({
            toggle: true,
            message: data.message,
          }),
        );
      }
    } catch (error: any) {
      const err = error.response.data;
      dispatch(
        toggleAlert({
          toggle: true,
          message: err?.message,
          type: 'error',
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDisconnectPage = async (
    page: any,
    objectId: string,
    model: string,
  ) => {
    try {
      setLoading(true);
      setIsDirty(true);

      const formObj: PageFormFields = {
        accessToken: page?.accessToken,
        name: page?.name,
        fbPageId: page?.fbPageId,
        imageUrl: page?.imageUrl,
        objectId,
        model,
      };

      const data = await disconnectPage(formObj);

      if (data) {
        setFormValues({ ...formValues, page: null });
        dispatch(
          toggleAlert({
            toggle: true,
            message: data.message,
          }),
        );
      }
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddResponses = () => {
    setIsDirty(true);
    let newFormObj = { ...formValues };
    newFormObj.cannedResponses = [
      ...formValues.cannedResponses,
      { message: '', isDeleted: false },
    ];

    setFormValues(newFormObj);
  };

  const handleDeleteReponses = (index: number) => {
    setIsDirty(true);
    let newFormObj = { ...formValues };
    newFormObj.cannedResponses[index].isDeleted = true;

    setFormValues(newFormObj);
  };

  const handleEditResponses = (index: number, value: string) => {
    setIsDirty(true);
    let newFormObj = { ...formValues };
    newFormObj.cannedResponses[index].message = value;

    setFormValues(newFormObj);
  };

  const handleSetCustomFields = (
    selectedForm: number,
    customFields: string,
  ) => {
    setIsDirty(true);
    let newFormObj = { ...formValues };
    const message = newFormObj.cannedResponses[selectedForm].message;
    newFormObj.cannedResponses[
      selectedForm
    ].message = `${message} ${customFields}`;

    setFormValues(newFormObj);
  };

  const buildCannedResponses = () => {
    let newFormObj = { ...formValues };
    let newCannedResponses: CannedResponse[] = [];
    newFormObj.cannedResponses.forEach((response: CannedResponse) => {
      if (!response.isDeleted) {
        newCannedResponses = [...newCannedResponses, response];
      } else {
        return;
      }
    });

    return newCannedResponses;
  };

  const handleOpenPageListModal = () => {
    setOpenPageList(true);
  };

  const handleClosePageListModal = () => {
    setOpenPageList(false);
  };

  const handleOpenSalespersonForm = () => {
    setShowSalespersonForm(true);
  };

  const handleCloseSalespersonForm = () => {
    setShowSalespersonForm(false);
  };

  const handleOpenManagerForm = () => {
    setShowManagerForm(true);
  };

  const handleCloseManagerForm = () => {
    setShowManagerForm(false);
  };

  const handleOpenMarketingManagerForm = (index: number) => {
    setIsDirty(true);
    let userForms: boolean[] = [...showMultipleUserForms];

    userForms[index] = true;

    setShowMultipleUserForms(userForms);
  };

  const handleCloseMarketingManagerForm = (index: number) => {
    let userForms: boolean[] = [...showMultipleUserForms];

    userForms[index] = false;

    setShowMultipleUserForms(userForms);
  };

  const handleOpenInviteModal = (info: InviteInfo) => {
    setOpenInviteModal(true);
    setInvitationInfo({
      ...invitationInfo,
      name: info.name,
      email: info.email,
      id: info.id,
      role: info.role,
    });
  };

  const handleCloseInviteModal = () => {
    setInvitationErrors({
      ...invitationErrors,
      email: '',
    });
    setOpenInviteModal(false);
  };

  const resetDiyForm = () => {
    setDiySettingForm({
      ref: brand?._id,
      docModel: 'Brand',
      allowTrafficAds: true,
      allowLeadAds: false,
      allowMessengerAds: false,
      allowAppAds: false,
      allowAllGeolocation: false,
      locationTypes: ['address'],
      excludedLocationTypes: [],
      excludedGeolocations: null,
      excludeGeolocation: false,
      defaultUrl: '',
      defaultEmploymentUrl: '',
      defaultCalendarUrl: '',
      defaultPrivacyPolicyUrl: '',
      appDeepLink: '',
      maxCityRadius: 50,
      maxAddressRadius: 50,
      allowSetupOwnAccount: formValues?.__type === 'brand',
      geolocation: null,
      facebookPage: null,
      instagramAccount: null,
      fbAdAccount: null,
      defaultLocation: null,
      defaultUser: null,
      targetCountries: [{ code: 'US', label: 'United States', phone: '1' }],
    });
  };

  const resetForm = () => {
    setFormValues({
      brand: '',
      allLeads: 'No',
      zipcodereq: 'No',
      financialreq: 'Yes',
      redirect: 'No',
      redirectlink: '',
      redirecttext: '',
      industryRequirement: 'No',
      schedulecall: 'No',
      industryQuestion: '',
      calendly: '',
      salesperson: {
        name: '',
        _id: '',
        email: '',
        role: '',
        user: {
          email: '',
        },
      },
      salespersonTitle: '',
      page: null,
      phoneNumReq: 'No',
      budgetOffset: 100,
      clientTetherConfig: {
        accessToken: '',
        webKey: '',
        leadSourceId: '',
      },
      cannedResponses: [
        {
          message: `Hi <<lead>>! Good day, I'm <<salesperson>> of <<brand>>, how can I help you?`,
          isDeleted: false,
        },
        {
          message: `Hi <<lead>> you want to schedule a call? Here's my Calendly link <<calendly_link>>.`,
          isDeleted: false,
        },
        {
          message:
            'I am glad I was able to help. Please tell me if you have any more queries 🙂',
          isDeleted: false,
        },
      ],
      manager: {
        name: '',
        _id: '',
        email: '',
        role: '',
        user: {
          email: '',
        },
      },
      active: true,
      paused: false,
      lastPausedAt: null,
      facebookBusinessIds: [],
      googleCustomerIds: [],
      allowLocations: false,
      agency: {
        _id: '',
        agency: '',
      },
      __type: 'brand',
      allowSetupDepositAccount: false,
      allowSetupPayment: false,
      analyticTools: [],
      industry: '',
      businessType: '',
      diy: IS_DIY_ADZ,
      phoneNumber: '',
      address: {
        line1: '',
        city: '',
        state: '',
        zipcode: '',
        lat: null,
        lng: null,
      },
      users: [],
    });
  };

  const handleAllowAdBudget = async (
    feeType: string,
    allowed: boolean,
    entity: string,
  ) => {
    setIsDirty(true);
    try {
      const IS_BRAND = entity === 'brand';
      const IS_LOCATION = entity === 'location';

      const temp = [...(IS_BRAND ? brandFeesForm : locationFeesForm)];

      const fee: Fee = temp.find((fee: Fee) => {
        return fee.type === feeType;
      });

      fee.enabled = allowed;

      if (IS_BRAND) {
        setBrandFeesForm(temp);
      } else if (IS_LOCATION) {
        setLocationFeesForm(temp);
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const handleConfirmNavigation = () => {
    autoUnblockingTx.retry();
    setOpenConfirmNavModal(false);
  };

  const handleCancelNavigation = () => {
    setOpenConfirmNavModal(false);
  };

  const handleRemoveUser = (index: number) => {
    setIsDirty(true);
    let showForms: boolean[] = [...showMultipleUserForms];
    let users: Salesperson[] = [...formValues?.users];
    if (users[index]) {
      if (users[index]?._id === diySettingForm?.defaultUser?._id) {
        setDiySettingForm({ ...diySettingForm, defaultUser: null });
      }

      users.splice(index, 1);
      setFormValues({ ...formValues, users });
    }

    showForms.splice(index, 1);
    setFormCount((prev) => prev - 1);
  };

  const handleAddUser = () => {
    setIsDirty(true);
    setShowMultipleUserForms([...showMultipleUserForms, false]);
    setFormCount((prev) => prev + 1);
  };

  const multipleUserForm = () => {
    let components: any[] = [];

    for (let i = 0; i < formCount; i++) {
      const disabled = (formValues?.users || [])[i]?._id === roleBasedId;

      components = [
        ...components,
        <>
          <Grid item key={i + 1} xs={12}>
            <p className={styles.label}>{`User #${i + 1}`}</p>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Autocomplete
                  disabled={disabled}
                  id={`user-${i + 1}`}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option._id}>
                        {`${option.salespersonId}. ${option.name}`}
                      </li>
                    );
                  }}
                  options={salespersons.sort(
                    (a, b) => -b.name?.localeCompare(a.name),
                  )}
                  value={(formValues?.users || [])[i] || null}
                  getOptionLabel={(option) => `${option.name}`}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Name"
                      placeholder="Select a user"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      size="small"
                      error={errors?.users && i === 0 ? true : false}
                      helperText={errors?.users && i === 0 ? errors?.users : ''}
                    />
                  )}
                  disableClearable
                  onChange={async (e: any, value: any) => {
                    setIsDirty(true);
                    let users: any[] = [...(formValues?.users || [])];
                    if (users.length === 0) {
                      users = [...users, value];
                    } else {
                      users[i] = value;
                    }

                    setFormValues({ ...formValues, users });
                  }}
                  getOptionDisabled={(option) => {
                    const ids = formValues?.users?.map((user: any) => {
                      return user._id;
                    });
                    return ids?.includes(option._id);
                  }}
                />
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  justifyContent: 'left',
                  alignItems: 'left',
                  display: 'flex',
                }}
              >
                {!showMultipleUserForms[i] ? (
                  <PrimaryButton
                    title={`${
                      (formValues?.users || [])[i] ? 'Edit' : 'Create'
                    } User`}
                    type="button"
                    variant="text"
                    startIcon={<PersonAddAltOutlinedIcon />}
                    fontSize="12px"
                    handleOnClick={() => handleOpenMarketingManagerForm(i)}
                    disabled={disabled}
                  />
                ) : null}

                <PrimaryButton
                  title="Remove User"
                  type="button"
                  variant="text"
                  startIcon={<GroupRemoveOutlinedIcon />}
                  fontSize="12px"
                  handleOnClick={() => handleRemoveUser(i)}
                  disabled={disabled}
                />

                {!(formValues.users || [])[i]?._id ||
                (formValues.users || [])[i]?.user?.email ? (
                  <PrimaryButton
                    title="Invite Accepted"
                    type="button"
                    variant="text"
                    startIcon={<ContactMailOutlinedIcon />}
                    fontSize="12px"
                    disabled
                  />
                ) : (
                  <PrimaryButton
                    title={
                      (formValues.users || [])[i]?.invitedAt
                        ? 'Resend Invite'
                        : 'Invite User'
                    }
                    type="button"
                    variant="text"
                    startIcon={<ContactMailOutlinedIcon />}
                    fontSize="12px"
                    handleOnClick={() => {
                      handleOpenInviteModal({
                        ...formValues?.users[i],
                      } as InviteInfo);
                    }}
                    disabled={disabled}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          {showMultipleUserForms[i] ? (
            <Grid item xs={12}>
              <BrandUserForm
                isLocation={false}
                salespersons={salespersons}
                setSalespersons={setSalespersons}
                marketingManagers={marketingManagers}
                setMarketingManagers={setMarketingManagers}
                brandFormValues={formValues}
                setBrandFormValues={setFormValues}
                onCloseManagerForm={handleCloseManagerForm}
                onCloseSalespersonForm={handleCloseSalespersonForm}
                onCloseMultipleForm={handleCloseMarketingManagerForm}
                role="marketing-manager"
                multiple
                index={i}
                edit={(formValues?.users || [])[i] ? true : false}
                salespersonId={
                  (formValues?.users || [])[i]
                    ? (formValues?.users || [])[i]._id
                    : null
                }
                capabilities={capabilities}
                isAdmin={canCreateBrand}
                isMarketingManager={isMarketingManager}
              />
            </Grid>
          ) : null}
        </>,
      ];
    }

    return components;
  };

  return (
    <Box
      className={`${styles.form} ${XsOnly() ? `${styles['-mobile']}` : ''}`}
      justifyContent="center"
    >
      <CircularLoading loading={fetchLoading} />

      <PageHeader title={isEdit ? 'Edit Brand' : 'Add New Brand'} />

      {/** BRAND DETAILS SECTION */}
      {canCreateBrand ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                variant="standard"
                type="text"
                id="brand"
                name="brand"
                label="Brand"
                onChange={(e: ChangeEventType) => {
                  setIsDirty(true);
                  setFormValues({ ...formValues, brand: e.target.value });
                }}
                value={formValues.brand}
                InputLabelProps={{ shrink: true }}
                size="small"
                autoFocus
                error={errors.brand ? true : false}
                helperText={errors?.brand ? errors?.brand : ''}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Autocomplete
                id="agency"
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option._id}>
                      {option.agency}
                    </li>
                  );
                }}
                options={agencies.sort(
                  (a, b) => -b.agency?.localeCompare(a.agency),
                )}
                value={formValues.agency}
                getOptionLabel={(option) => option.agency}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Agency"
                    fullWidth
                    size="small"
                    placeholder="Select an agency"
                    required
                    InputLabelProps={{ shrink: true }}
                    error={errors?.agency ? true : false}
                    helperText={errors?.agency ? errors?.agency : ''}
                  />
                )}
                disableClearable
                onChange={async (e: any, value: any) => {
                  setIsDirty(true);
                  setFormValues({
                    ...formValues,
                    agency: {
                      ...value,
                    },
                  });
                }}
              />
            </Grid>
          </Grid>

          {!IS_DIY_ADZ ? (
            <>
              <p className={styles.label}>Salesperson:</p>

              <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={7}>
                      <Autocomplete
                        id="salesperson"
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option._id}>
                              {`${option.salespersonId}. ${option.name}`}
                            </li>
                          );
                        }}
                        options={salespersons.sort(
                          (a, b) => -b.name?.localeCompare(a.name),
                        )}
                        value={formValues?.salesperson}
                        getOptionLabel={(option) => `${option.name}`}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Name"
                            fullWidth
                            size="small"
                            placeholder="Select a salesperson"
                            required
                            InputLabelProps={{ shrink: true }}
                            error={errors?.salesperson ? true : false}
                            helperText={
                              errors?.salesperson ? errors?.salesperson : ''
                            }
                          />
                        )}
                        disableClearable
                        onChange={async (e: any, value: any) => {
                          setIsDirty(true);
                          setFormValues({
                            ...formValues,
                            salesperson: { ...value },
                          });
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      lg={5}
                      sx={{
                        justifyContent: 'left',
                        alignItems: 'left',
                        display: 'flex',
                      }}
                    >
                      {!showSalespersonForm ? (
                        <PrimaryButton
                          title="Add User"
                          type="button"
                          startIcon={<AddIcon />}
                          fontSize="12px"
                          handleOnClick={handleOpenSalespersonForm}
                        />
                      ) : null}

                      {!formValues.salesperson?._id ||
                      formValues.salesperson?.user?.email ? (
                        <PrimaryButton
                          title="Invite Accepted"
                          type="button"
                          variant="text"
                          startIcon={<ContactMailOutlinedIcon />}
                          fontSize="12px"
                          disabled
                        />
                      ) : (
                        <PrimaryButton
                          title={
                            formValues?.salesperson?.invitedAt
                              ? 'Resend Invite'
                              : 'Invite User'
                          }
                          type="button"
                          variant="text"
                          startIcon={<ContactMailOutlinedIcon />}
                          fontSize="12px"
                          handleOnClick={() => {
                            handleOpenInviteModal({
                              ...formValues?.salesperson,
                            } as InviteInfo);
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    variant="standard"
                    type="text"
                    id="salespersonTitle"
                    name="salespersonTitle"
                    label="Title"
                    onChange={(e: ChangeEventType) => {
                      setIsDirty(true);
                      setFormValues({
                        ...formValues,
                        salespersonTitle: e.target.value,
                      });
                    }}
                    value={formValues.salespersonTitle}
                    placeholder="Sales Manager"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                  />
                </Grid>
              </Grid>
            </>
          ) : null}

          {showSalespersonForm ? (
            <Grid container spacing={2} mb={1}>
              <Grid item xs={12}>
                <BrandUserForm
                  isLocation={false}
                  salespersons={salespersons}
                  setSalespersons={setSalespersons}
                  managers={managers}
                  setManagers={setManagers}
                  brandFormValues={formValues}
                  setBrandFormValues={setFormValues}
                  role="salesperson"
                  onCloseSalespersonForm={handleCloseSalespersonForm}
                  onCloseManagerForm={handleCloseManagerForm}
                />
              </Grid>
            </Grid>
          ) : null}

          {!IS_DIY_ADZ ? (
            <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
              <Grid item xs={12} md={6}>
                <p className={styles.label}>Manager:</p>

                <Grid container spacing={2}>
                  <Grid item xs={12} lg={7}>
                    <Autocomplete
                      id="manager"
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option._id}>
                            {`${option.salespersonId}. ${option.name}`}
                          </li>
                        );
                      }}
                      options={managers.sort(
                        (a, b) => -b.name?.localeCompare(a.name),
                      )}
                      value={formValues?.manager}
                      getOptionLabel={(option) => `${option.name}`}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Name"
                          placeholder="Select a manager"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          size="small"
                        />
                      )}
                      disableClearable
                      onChange={async (e: any, value: any) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          manager: { ...value },
                        });
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    lg={5}
                    sx={{
                      justifyContent: 'left',
                      alignItems: 'left',
                      display: 'flex',
                    }}
                  >
                    {!showManagerForm ? (
                      <PrimaryButton
                        title="Create User"
                        type="button"
                        variant="text"
                        startIcon={<AddIcon />}
                        fontSize="12px"
                        handleOnClick={handleOpenManagerForm}
                      />
                    ) : null}

                    {!formValues.manager?._id ||
                    formValues.manager?.user?.email ? (
                      <PrimaryButton
                        title="Invite Accepted"
                        type="button"
                        variant="text"
                        startIcon={<ContactMailOutlinedIcon />}
                        fontSize="12px"
                        disabled
                      />
                    ) : (
                      <PrimaryButton
                        title={
                          formValues?.manager?.invitedAt
                            ? 'Resend Invite'
                            : 'Invite User'
                        }
                        type="button"
                        variant="text"
                        startIcon={<ContactMailOutlinedIcon />}
                        fontSize="12px"
                        handleOnClick={() => {
                          handleOpenInviteModal({
                            ...formValues?.manager,
                          } as InviteInfo);
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}

          {showManagerForm ? (
            <Grid container spacing={2} mb={1}>
              <Grid item xs={12}>
                <BrandUserForm
                  isLocation={false}
                  salespersons={salespersons}
                  setSalespersons={setSalespersons}
                  managers={managers}
                  setManagers={setManagers}
                  brandFormValues={formValues}
                  setBrandFormValues={setFormValues}
                  onCloseManagerForm={handleCloseManagerForm}
                  onCloseSalespersonForm={handleCloseSalespersonForm}
                  role="brand-manager"
                />
              </Grid>
            </Grid>
          ) : null}

          {IS_DIY_ADZ &&
          (canCreateBrand ||
            (!(isSuperAdmin || isAdmin) && capabilities?.addUser)) ? (
            <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
              {multipleUserForm()}

              <Grid item xs={12}>
                {formCount !== MAX_USER_COUNT ? (
                  <PrimaryButton
                    title="Add User"
                    type="button"
                    handleOnClick={handleAddUser}
                    startIcon={<AddIcon />}
                  />
                ) : null}
              </Grid>
            </Grid>
          ) : null}

          {canCreateBrand ? (
            <div>
              <p className={styles.label}>Campaign Setup</p>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="standard"
                    type="number"
                    id="budgetOffset"
                    name="budgetOffset"
                    label="Budget Offset"
                    onChange={(e: ChangeEventType) => {
                      setIsDirty(true);
                      setFormValues({
                        ...formValues,
                        budgetOffset: parseFloat(e.target.value),
                      });
                    }}
                    value={formValues.budgetOffset}
                    placeholder="0"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    sx={{
                      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                        {
                          display: 'none',
                        },
                      '& input[type=number]': {
                        MozAppearance: 'textfield',
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          ) : null}

          <Grid container spacing={2} mb={1} mt={1}>
            <Grid item xs={12} sm={6}>
              <FormControl>
                <FormLabel id="status">Brand Status</FormLabel>

                <FormControlLabel
                  control={
                    <Switch
                      checked={formValues.active}
                      name="active"
                      onChange={(e: any) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          [e.target.name]: e.target.checked,
                        });
                      }}
                    />
                  }
                  label={formValues.active ? 'Active' : 'Inactive'}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl>
                <FormLabel id="status">Campaign Status</FormLabel>

                <FormControlLabel
                  control={
                    <Switch
                      checked={!formValues.paused}
                      name="paused"
                      onChange={(e: any) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          [e.target.name]: !e.target.checked,
                        });
                      }}
                    />
                  }
                  label={!formValues.paused ? 'Running' : 'Paused'}
                />
              </FormControl>
            </Grid>
          </Grid>

          {canCreateBrand ? (
            <>
              <Grid item xs={12} mt={2} mb={2}>
                <Divider />
              </Grid>

              <p className={styles.label}>Payment Setup</p>
            </>
          ) : null}

          {canCreateBrand ? (
            <Grid container spacing={2} mb={1}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel id="status">Allow Credit Card Setup</FormLabel>

                  <FormControlLabel
                    control={
                      <Switch
                        checked={formValues.allowSetupPayment}
                        name="allowSetupPayment"
                        onChange={(e: any) => {
                          setIsDirty(true);
                          setFormValues({
                            ...formValues,
                            [e.target.name]: e.target.checked,
                          });
                        }}
                      />
                    }
                    label={
                      formValues.allowSetupPayment || false
                        ? 'Enabled'
                        : 'Disabled'
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          ) : null}

          {formValues.allowSetupPayment && canCreateBrand && IS_DIY_ADZ ? (
            <Grid container spacing={2} mt={1} mb={1}>
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  type="number"
                  label={`Subscription Fee Percentage`}
                  onChange={(e: ChangeEventType) => {
                    setIsDirty(true);
                    setFormValues({
                      ...formValues,
                      budgetPercentageFee: parseFloat(e.target.value),
                    });
                  }}
                  value={formValues?.budgetPercentageFee}
                  placeholder="18"
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  sx={{
                    marginRight: '10px',
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                      {
                        display: 'none',
                      },
                    '& input[type=number]': {
                      MozAppearance: 'textfield',
                    },
                  }}
                />
              </Grid>
            </Grid>
          ) : null}

          {canCreateBrand ? (
            <Grid container spacing={2} mb={1}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel id="status">Enable Deposit Account</FormLabel>

                  <FormControlLabel
                    control={
                      <Switch
                        checked={formValues.allowSetupDepositAccount}
                        name="allowSetupDepositAccount"
                        onChange={(e: any) => {
                          setIsDirty(true);
                          setFormValues({
                            ...formValues,
                            [e.target.name]: e.target.checked,
                          });
                        }}
                      />
                    }
                    label={
                      formValues.allowSetupDepositAccount || false
                        ? 'Enabled'
                        : 'Disabled'
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          ) : null}

          <Grid item xs={12} mt={2} mb={2}>
            <Divider />
          </Grid>

          <p className={styles.label}>Location Setup</p>

          <Grid container spacing={2} mb={1}>
            <Grid item xs={12} md={6}>
              <FormControl>
                <FormLabel id="status">Allow Multiple Locations</FormLabel>

                <FormControlLabel
                  control={
                    <Switch
                      checked={formValues.allowLocations}
                      name="allowLocations"
                      onChange={(e: any) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          [e.target.name]: e.target.checked,
                        });
                      }}
                    />
                  }
                  label={
                    formValues.allowLocations || false ? 'Enabled' : 'Disabled'
                  }
                />
              </FormControl>
            </Grid>

            {formValues.allowLocations && canCreateBrand ? (
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel id="status">
                    Allow Locations Setup Payment
                  </FormLabel>

                  <FormControlLabel
                    control={
                      <Switch
                        checked={formValues.allowLocationsSetupPayment}
                        name="allowLocationsSetupPayment"
                        onChange={(e: any) => {
                          setIsDirty(true);
                          setFormValues({
                            ...formValues,
                            [e.target.name]: e.target.checked,
                          });
                        }}
                      />
                    }
                    label={
                      formValues.allowLocationsSetupPayment || false
                        ? 'Enabled'
                        : 'Disabled'
                    }
                  />
                </FormControl>
              </Grid>
            ) : null}
          </Grid>

          {canCreateBrand ? (
            <>
              <Grid item xs={12} mb={1}>
                <Divider />
              </Grid>

              <Grid item xs={12} mb={1}>
                <p className={styles.label}>Analytic Tools</p>
              </Grid>

              {!fetchLoading ? (
                <Grid item xs={12} mb={1}>
                  {analyticTools.map((tool: string, index: number) => {
                    return (
                      <FormControlLabel
                        key={`analytic-tools-${index}`}
                        control={
                          <Checkbox
                            size="small"
                            defaultChecked={formValues?.analyticTools?.includes(
                              tool,
                            )}
                          />
                        }
                        label={humanizeString(tool)}
                        value={formValues?.analyticTools?.includes(tool)}
                        onChange={(e: any) => {
                          setIsDirty(true);
                          let temp: string[] = [];
                          if (e.target.checked) {
                            temp = [...formValues?.analyticTools, tool];
                          } else {
                            formValues?.analyticTools?.forEach(
                              (analyticTool: string) => {
                                if (analyticTool !== tool) {
                                  temp = [...temp, analyticTool];
                                }
                              },
                            );
                          }

                          setFormValues({
                            ...formValues,
                            analyticTools: temp,
                          });
                        }}
                      />
                    );
                  })}
                </Grid>
              ) : null}
            </>
          ) : null}

          {/** DIY SETTINGS */}
          {IS_DIY_ADZ && !diySettingLoading ? (
            <>
              <Grid item xs={12} mb={1}>
                <Divider />
              </Grid>

              <DiySettingsForm
                formValues={formValues}
                setFormValues={setFormValues}
                accessToken={defaultAccessToken}
                isAdmin={canCreateBrand}
                isMarketingManager={isMarketingManager}
                isFranchisee={formValues?.__type === 'location'}
                setLoading={setFetchLoading}
                userId={roleBasedId}
                role={role}
                diySettings={diySettingForm}
                setDiySettings={setDiySettingForm}
                loading={fetchLoading}
                isEdit={isEdit}
                setIsDirty={setIsDirty}
                errors={errors}
                diySettingsErrors={diySettingsErrors}
              />
            </>
          ) : null}

          {!IS_DIY_ADZ ? (
            <>
              <Grid item xs={12} mt={2}>
                <Divider />
              </Grid>

              <Grid container spacing={2} mb={2}>
                <Grid item xs={12}>
                  {defaultAccessToken && canConnectPage ? (
                    <ConnectFacebookPageForm
                      page={formValues.page}
                      objectId={brand?._id}
                      model="Brand"
                      handleConnect={handleConnectPage}
                      handleDisconnect={handleDisconnectPage}
                      loading={loading}
                      open={openPageList}
                      handleOpenPageList={handleOpenPageListModal}
                      handleClosePageList={handleClosePageListModal}
                      accessToken={defaultAccessToken}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </>
          ) : null}

          {formValues?.page?._id ? (
            <>
              <p className={styles.label}>Investment Requirement</p>

              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    variant="standard"
                    type="text"
                    id="text"
                    name="text"
                    label="Text"
                    onChange={(e: ChangeEventType) =>
                      setInvestmentRequirement({
                        ...investmentRequirement,
                        text: e.target.value,
                      })
                    }
                    value={investmentRequirement.text}
                    placeholder="$80k"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    variant="standard"
                    type="number"
                    id="num"
                    name="num"
                    label="Num"
                    onChange={(e: ChangeEventType) =>
                      setInvestmentRequirement({
                        ...investmentRequirement,
                        num: Number(e.target.value),
                      })
                    }
                    value={investmentRequirement.num}
                    placeholder="80"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    sx={{
                      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                        {
                          display: 'none',
                        },
                      '& input[type=number]': {
                        MozAppearance: 'textfield',
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    variant="standard"
                    type="number"
                    id="top"
                    name="top"
                    label="Top"
                    onChange={(e: ChangeEventType) =>
                      setInvestmentRequirement({
                        ...investmentRequirement,
                        top: Number(e.target.value),
                      })
                    }
                    value={investmentRequirement.top}
                    placeholder="81"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    sx={{
                      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                        {
                          display: 'none',
                        },
                      '& input[type=number]': {
                        MozAppearance: 'textfield',
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={2} sx={{ mt: 1 }}>
                  <FormControl>
                    <FormLabel id="allLeads">All Leads</FormLabel>

                    <RadioGroup
                      row
                      aria-labelledby="allLeads"
                      name="allLeads"
                      value={formValues.allLeads}
                      onChange={(e: ChangeEventType) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      defaultValue="No"
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />

                      <FormControlLabel
                        value="No"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={2} sx={{ mt: 1 }}>
                  <FormControl>
                    <FormLabel id="zipcodereq">Zip code req</FormLabel>

                    <RadioGroup
                      row
                      aria-labelledby="zipcodereq"
                      name="zipcodereq"
                      value={formValues.zipcodereq}
                      onChange={(e: ChangeEventType) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      defaultValue="No"
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />

                      <FormControlLabel
                        value="No"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={2} sx={{ mt: 1 }}>
                  <FormControl>
                    <FormLabel id="financialreq">Financial req</FormLabel>

                    <RadioGroup
                      row
                      aria-labelledby="financialreq"
                      name="financialreq"
                      value={formValues.financialreq}
                      onChange={(e: ChangeEventType) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      defaultValue="Yes"
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />

                      <FormControlLabel
                        value="No"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={2} mt={1}>
                  <FormControl>
                    <FormLabel id="phoneNumReq">Phone Num Req</FormLabel>

                    <RadioGroup
                      row
                      aria-labelledby="phoneNumReq"
                      name="phoneNumReq"
                      value={formValues.phoneNumReq}
                      onChange={(e: ChangeEventType) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      defaultValue="No"
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />

                      <FormControlLabel
                        value="No"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2} mb={1}>
                <Grid item xs={12} sm={4}>
                  <FormControl>
                    <FormLabel id="redirect">Redirect</FormLabel>

                    <RadioGroup
                      row
                      aria-labelledby="redirect"
                      name="redirect"
                      value={formValues.redirect}
                      onChange={(e: ChangeEventType) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      defaultValue="No"
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />

                      <FormControlLabel
                        value="No"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>

              {formValues.redirect === 'Yes' ? (
                <Grid container spacing={2} mb={1}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="redirectLink"
                      name="redirectLink"
                      label="Redirect Link"
                      onChange={(e: ChangeEventType) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          redirectlink: e.target.value,
                        });
                      }}
                      value={formValues.redirectlink}
                      InputLabelProps={{ shrink: true }}
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="redirectText"
                      name="redirectText"
                      label="Redirect Text"
                      onChange={(e: ChangeEventType) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          redirecttext: e.target.value,
                        });
                      }}
                      value={formValues.redirecttext}
                      InputLabelProps={{ shrink: true }}
                      size="small"
                    />
                  </Grid>
                </Grid>
              ) : null}

              <Grid container spacing={2} mb={1}>
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel id="industryRequirement">
                      Industry Required
                    </FormLabel>

                    <RadioGroup
                      row
                      aria-labelledby="industryRequirement"
                      name="industryRequirement"
                      value={formValues.industryRequirement}
                      onChange={(e: ChangeEventType) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      defaultValue="No"
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />

                      <FormControlLabel
                        value="No"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>

              {formValues.industryRequirement === 'Yes' ? (
                <Grid container spacing={2} mb={1}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="industryQuestion"
                      name="industryQuestion"
                      label="Industry Ques"
                      onChange={(e: ChangeEventType) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          industryQuestion: e.target.value,
                        });
                      }}
                      value={formValues.industryQuestion}
                      InputLabelProps={{ shrink: true }}
                      size="small"
                    />
                  </Grid>
                </Grid>
              ) : null}

              <Grid container spacing={2} mb={1}>
                <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                  <FormControl>
                    <FormLabel id="schedulecall">Schedule call</FormLabel>

                    <RadioGroup
                      row
                      aria-labelledby="schedulecall"
                      name="schedulecall"
                      value={formValues.schedulecall}
                      onChange={(e: ChangeEventType) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      defaultValue="Yes"
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />

                      <FormControlLabel
                        value="No"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>

              {formValues.schedulecall === 'Yes' ? (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      id="calendly"
                      name="calendly"
                      label="Calendly"
                      onChange={(e: ChangeEventType) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          calendly: e.target.value,
                        });
                      }}
                      value={formValues.calendly}
                      placeholder=""
                      InputLabelProps={{ shrink: true }}
                      size="small"
                    />
                  </Grid>
                </Grid>
              ) : null}
            </>
          ) : null}

          <Divider />
        </>
      ) : null}

      {IS_DIY_ADZ && capabilities?.addUser && !canCreateBrand ? (
        <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
          {multipleUserForm()}

          <Grid item xs={12}>
            {formCount !== MAX_USER_COUNT ? (
              <PrimaryButton
                title="Add User"
                type="button"
                handleOnClick={handleAddUser}
                startIcon={<AddIcon />}
              />
            ) : null}
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      ) : null}

      {/** DIY SETTINGS */}
      {IS_DIY_ADZ && !canCreateBrand && !diySettingLoading ? (
        <DiySettingsForm
          formValues={formValues}
          setFormValues={setFormValues}
          accessToken={defaultAccessToken}
          isAdmin={canCreateBrand}
          isMarketingManager={isMarketingManager}
          isFranchisee={formValues?.__type === 'location'}
          setLoading={setFetchLoading}
          brand={formValues?.franchisor}
          userId={roleBasedId}
          role={role}
          diySettings={diySettingForm}
          setDiySettings={setDiySettingForm}
          loading={fetchLoading}
          isEdit={isEdit}
          setIsDirty={setIsDirty}
          errors={errors}
          diySettingsErrors={diySettingsErrors}
        />
      ) : null}

      {/** CANNED RESPONSES SECTION */}
      {formValues?.page?._id ? (
        <>
          <p className={styles.label}>Canned Responses</p>

          <Stack direction="row" spacing={1} sx={{ marginBottom: '15px' }}>
            {CANNED_RESPONSE_FIELDS.map((field: any) => {
              return (
                <Chip
                  key={field}
                  label={`${field}`}
                  onClick={() => {
                    handleSetCustomFields(selectedForm, field);
                  }}
                />
              );
            })}
          </Stack>

          {formValues.cannedResponses?.map(
            (response: CannedResponse, index: number) => {
              return response.isDeleted ? null : (
                <Grid item xs={12} key={index}>
                  <TextField
                    fullWidth
                    multiline
                    variant="standard"
                    size="small"
                    type="text"
                    id={`cannedResponse-${index}`}
                    name={`cannedResponse-${index}`}
                    label={`Response #${index + 1}`}
                    onChange={(e: any) => {
                      handleEditResponses(index, e.target.value);
                    }}
                    value={response?.message}
                    InputLabelProps={{ shrink: true }}
                    onFocus={() => {
                      setSelectedForm(index);
                    }}
                  />

                  <IconButton
                    id={`delete-${index}`}
                    size="small"
                    edge="end"
                    aria-label="canned response"
                    aria-controls="canned-message"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={() => {
                      handleDeleteReponses(index);
                    }}
                  >
                    <DeleteIcon sx={{ color: red[500], fontSize: '30px' }} />
                  </IconButton>
                </Grid>
              );
            },
          )}

          <Grid item xs={12} sx={{ marginBottom: '15px' }}>
            <PrimaryButton
              title="Add Response"
              type="button"
              handleOnClick={handleAddResponses}
              variant="text"
              startIcon={<AddIcon />}
            />
          </Grid>

          <Divider />

          {/** CLIENT TETHER SECTION */}
          <p className={styles.label}>Client Tether Integration</p>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                variant="standard"
                size="small"
                id="accessToken"
                name="accessToken"
                label="Access Token"
                onChange={(e: ChangeEventType) => {
                  setIsDirty(true);
                  setFormValues({
                    ...formValues,
                    clientTetherConfig: {
                      ...formValues.clientTetherConfig,
                      accessToken: e.target.value,
                    },
                  });
                }}
                value={formValues.clientTetherConfig?.accessToken}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                variant="standard"
                size="small"
                id="webKey"
                name="webKey"
                label="Web Key"
                onChange={(e: ChangeEventType) => {
                  setIsDirty(true);
                  setFormValues({
                    ...formValues,
                    clientTetherConfig: {
                      ...formValues.clientTetherConfig,
                      webKey: e.target.value,
                    },
                  });
                  //getLeadSource(configValues?.accessToken, e.target.value);
                }}
                value={formValues.clientTetherConfig?.webKey}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                variant="standard"
                id="leadResource"
                name="leadResource"
                select
                label="Lead Resource"
                placeholder="Select your lead resource"
                size="small"
                InputLabelProps={{ shrink: true }}
                value={formValues.clientTetherConfig?.leadSourceId}
                onChange={(e: ChangeEventType) => {
                  setIsDirty(true);
                  setFormValues({
                    ...formValues,
                    clientTetherConfig: {
                      ...formValues.clientTetherConfig,
                      leadSourceId: e.target.value,
                    },
                  });
                }}
              >
                {leadSources.map((leadSource: any) => (
                  <MenuItem
                    key={leadSource.lead_source_id}
                    value={leadSource?.lead_source_id}
                  >
                    {leadSource.lead_source_name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </>
      ) : null}

      <div className={styles.action}>
        <PrimaryButton
          title={isEdit ? 'Save' : 'Add'}
          loading={loading}
          size="large"
          rounded
          type="button"
          handleOnClick={handleOnSubmit}
        />
      </div>

      <InviteModal
        title={`Invite as ${humanizeString(invitationInfo.role)}`}
        subtitle={`Are you sure you want to invite ${
          invitationInfo.name
        } as ${humanizeString(invitationInfo.role)}?`}
        open={openInviteModal}
        handleClose={handleCloseInviteModal}
        invitationInfo={invitationInfo}
        handleInvite={handleInviteSalesperson}
        loading={inviteLoading}
        errors={invitationErrors}
      />

      <ConfirmNavigationModal
        open={openConfirmNavModal}
        onClose={handleCancelNavigation}
        onConfirm={handleConfirmNavigation}
      />
    </Box>
  );
};

export default BrandForm;
