export const googleEnums = {
  keywords: ['UNSPECIFIED', 'UNKNOWN', 'EXACT', 'PHRASE', 'BROAD'],
  advertisingChannel: [
    'UNSPECIFIED',
    'UNKNOWN',
    'SEARCH',
    'DISPLAY',
    'SHOPPING',
    'HOTEL',
    'VIDEO',
    'MULTI CHANNEL',
    'LOCAL',
    'SMART',
    'PERFORMANCE MAX',
    'LOCAL SERVICES',
    'DISCOVERY',
    'TRAVEL',
  ],
  campaignStatus: ['UNSPECIFIED', 'UNKNOWN', 'ENABLED', 'PAUSED', 'REMOVED'],
};

export const googleHelpers = {
  micros: (value: number) => {
    return value / 1000000;
  },
};
