import React, { useEffect, useState } from 'react';
import {
  FBLeadGenForm,
  FBLeadGenQuestionForm,
  LeadFormData,
} from '../../../types';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { editLeadForm, getSingleLeadForms } from '../../../services/lead';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import styles from '../../../assets/styles/components/Forms/Form.module.scss';
import CircularLoading from '../../CircularLoading';
import PageHeader from '../../PageHeader';
import LeadFormQualifyingQuestions from './Leads/LeadFormQualifyingQuestions';
import { FloatingButton } from '../../Buttons';
import { toggleAlert } from '../../../redux/actions';
import { errorMessageParser } from '../../../utils/helpers/ToastHelper';

const FacebookQLeadsForm: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const params = useParams();
  const leadFormId: string = params.leadFormId;

  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [leadForm, setLeadForm] = useState<LeadFormData>(null);
  const [formValues, setFormValues] = useState<FBLeadGenForm>(null);
  const [customQuestions, setCustomQuestions] = useState<
    FBLeadGenQuestionForm[]
  >([]);
  const [prefilledQuestions, setPrefilledQuestions] = useState<
    FBLeadGenQuestionForm[]
  >([]);

  useEffect(() => {
    if (leadFormId) getLeadForm();
  }, [leadFormId]);

  const getLeadForm = async () => {
    try {
      setFetchLoading(true);

      const response = await getSingleLeadForms(leadFormId);

      const prefilledQuestions = response.data.fields?.questions.filter(
        (question: FBLeadGenQuestionForm) => question.type !== 'CUSTOM',
      );

      const customQuestions = response.data.fields?.questions.filter(
        (question: FBLeadGenQuestionForm) => question.type === 'CUSTOM',
      );

      setLeadForm(response.data);
      setFormValues(response.data.fields);
      setPrefilledQuestions(prefilledQuestions);
      setCustomQuestions(customQuestions);
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const handleOnSubmit = async () => {
    try {
      setLoading(true);

      const {
        _id,
        createdAt,
        updatedAt,
        enableSendLead: enableEmail,
        enableZapierWebhook: enableZapier,
        leadFormId: formId,
        ...others
      } = leadForm;

      const leadFormTemp: LeadFormData = {
        ...others,
        fields: { ...formValues },
      };

      await editLeadForm(leadFormTemp, _id);

      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Successfully setup your lead notification',
        }),
      );

      navigate('/assetz');
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className={`${styles.form} ${xsOnly ? `${styles['-mobile']}` : ''}`}>
      <CircularLoading loading={fetchLoading} />

      <Box
        component="div"
        sx={{
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'center',
          margin: 0,
          marginBottom: '20px',
        }}
      >
        <Box component="div" sx={{ paddingBottom: 0 }}>
          <PageHeader
            title="PLEASE CHOOSE YOUR LEAD QUALIFYING QUESTIONS"
            textAlign="left"
            marginBottom="0"
          />
        </Box>
      </Box>

      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sx={{ minHeight: 'calc(100vh - 222px)' }}>
          <LeadFormQualifyingQuestions
            formValues={formValues}
            setFormValues={setFormValues}
            customQuestions={customQuestions}
            prefilledQuestions={prefilledQuestions}
          />
        </Grid>
      </Grid>

      <FloatingButton
        title="Cancel"
        onClick={() => navigate('/assetz')}
        theme="red"
        right="135px"
        loading={loading}
      />

      <FloatingButton title="Save" onClick={handleOnSubmit} loading={loading} />
    </Box>
  );
};

export default FacebookQLeadsForm;
